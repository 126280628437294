import { useLanguage } from '@/hooks';
import { NMessage } from '@/components';

export const successMessage = (k1, k2) => {
  const { tl, t, PREFIX } = useLanguage();
  NMessage.success({
    content: t(k1, PREFIX.SUCCESS, { key: tl(k2) }),
  });
};

export const warningMessage = (k1, k2) => {
  const { tl, t, PREFIX } = useLanguage();
  NMessage.warning({
    content: t(k1, PREFIX.WARNING, { key: tl(k2) }),
  });
};

export const errorMessage = (k1, k2) => {
  const { tl, t, PREFIX } = useLanguage();
  NMessage.error({
    content: t(k1, PREFIX.ERROR, { key: tl(k2) }),
  });
};
